const sizes = {
  sm: 640,
  md: 1024,
  lg: 1800
};

export default function useStatamicThumbnail (id: string, mobileId: string | null, preset = '') {
  const suffix = preset ? `_${preset}` : '';
  const config = useRuntimeConfig();

  const imageSrc = {
    default: getImageSrc(`lg${suffix}`),
    srcset: getSrcSet(suffix, false),
    srcsetMobile: getSrcSet(suffix, true)
  };

  return imageSrc;

  function getImageSrc (preset: string, showMobile = false) {
    if (showMobile === true) {
      return `${config.public.thumbnailBaseUrl}${preset}/${btoa(String(mobileId))}`;
    }
    return `${config.public.thumbnailBaseUrl}${preset}/${btoa(id)}`;
  }

  function getSrcSet (suffix: string, mobile = false) {
    if (mobile && (!mobileId || mobileId === '')) {
      return null;
    }
    let srcset = '';
    for (const s of Object.entries(sizes)) {
      const sizeId = s[0];
      const w = s[1];
      srcset += `${getImageSrc(`${sizeId}${suffix}`, mobile)} ${w}w, `;
    }
    return srcset;
  }
}
