<template>
  <picture
    class="statamic-image"
  >
    <source
      v-if="statamicImageMobile.srcset"
      media="(max-width: 799px)"
      :srcset="statamicImageMobile.srcset"
    >
    <source
      v-else
      media="(max-width: 799px)"
      :srcset="statamicImage.srcset"
    >
    <source media="(min-width: 800px)" :srcset="statamicImage.srcset">
    <img
      class="image"
      :src="statamicImage.src"
      :alt="alt"
    >
  </picture>
</template>

<script setup lang="ts">

import useStatamicImage from '~/common/useStatamicImage';

const props = defineProps<{
  id: string;
  idMobile: string | undefined;
  cropping: '3:4' | '9:16' | '4:3' | '16:9' | '1:1' | 'slim' | 'wide' | 'square' | 'original';
  alt: string;
}>();

const { data: statamicImage } = await useAsyncData(
  `statamic-image-${props.id}-${props.cropping}`,
  (): Promise<{ src: string; srcset: string }> => useStatamicImage(props.id, props.cropping)
);

const { data: statamicImageMobile } = await useAsyncData(
  `statamic-image-mobile-${props.id}-${props.cropping}`,
  (): Promise<{
    src: string;
    srcset: string
  }> => useStatamicImage(props.idMobile, props.cropping));

</script>

<style scoped lang="scss">

.statamic-image {
  width: fit-content;
  height: 100%;
  max-height: 100%;
  margin: auto;

  &.-card {
    padding: 1.5rem;
    border-radius: 1rem;
    border: 0.77px solid rgba(255, 255, 255, 0.10);
    background: rgba(62, 56, 56, 0.10);
    backdrop-filter: blur(40px);

    >.image {
      object-fit: contain;
    }
  }

  &:not(.-card) {
    border-radius: var(--base-border-radius);
  }

  > .image {
    height: 100%;
    max-height: 100%;
    width: auto;
    -o-object-fit: cover;
    object-fit: cover;
    margin: auto;

    &::after {
      content: "";
      background-color: #f1f1f1;
      scale: 0.95;
      position: absolute;
      inset: 0;
      border-radius: var(--base-border-radius);
    }
  }
}

</style>
