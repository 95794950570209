export default function useCloudfrontUrl (url: string) {
  const s3Base = 'https://gridonic-headless.s3.eu-central-1.amazonaws.com/';
  const cloudFrontBase = 'https://ds67zsxoucfna.cloudfront.net/';

  if (url.includes('https://gridonic-headless.s3.eu-central-1.amazonaws.com/')) {
    return url.replaceAll(s3Base, cloudFrontBase);
  }

  return url;
}
